import { useWeb3React as useWeb3ReactCore } from "@web3-react/core";
import { useCallback, useEffect, useState } from "react";
import { injected, getErrorMessage } from "../blockchain";
export default function useEagerConnect() {
  const { activate, active, error, account } = useWeb3ReactCore();
  const [tried, setTried] = useState(false);

  const checkEager = useCallback(() => {
    injected.isAuthorized().then((isAuthorized) => {
      if (isAuthorized) {
        activate(injected, undefined, true).catch(() => {
          setTried(true);
        });
      } else {
        setTried(true);
      }
    });
  }, [activate]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      checkEager();
    }, 500);
    return () => {
      clearInterval(timerId);
    };
  }, [activate, active, account, checkEager]);

  useEffect(() => {
    if (active) {
      setTried(true);
    }
  }, [active, account]);
  if (error) getErrorMessage(error);
  return tried;
}
